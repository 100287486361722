import { FacebookIcon, InstagramIcon, YoutubeIcon } from "assets/icons/social";
import Button from "components/Button";
import React, { FC, memo } from "react";
import classNamesBind from "classnames/bind";
import s from "./index.module.scss";
import { useTranslation } from "react-i18next";

const cx = classNamesBind.bind(s);

const Footer: FC<{ showFondy?: any }> = ({ showFondy }) => {
  const [t] = useTranslation();
  return (
    <footer>
      <div className={cx("Footer")}>
        <p>Indigo mental © 2015 - 2025</p>
        <Socials classNames={"no-xs"} />
        <div className={cx("Buttons")}>
          {showFondy && (
            <span className={cx("FondyLink")} onClick={() => showFondy && showFondy()}>
              {/* {t("condition.fondy")} */}
              Terms and conditions
            </span>
          )}
          <Button size={"small"} color={"orange"} href={"https://forms.gle/hLDuk9E2HnrXmXFC6"} howTagA classNames={"no-xs"} label={"franchise"} />
        </div>
      </div>
    </footer>
  );
};
export default memo(Footer);

export const Socials: FC<{ classNames?: string }> = ({ classNames }) => {
  return (
    <div className={cx("Socials", classNames)}>
      <a target={"_blank"} href="https://www.facebook.com/indigo.mental">
        <FacebookIcon />
      </a>
      <a target="_blank" href="https://www.youtube.com/channel/UClJPwZ5I1maMn-VJQHJdGQw">
        <YoutubeIcon />
      </a>
      <a target={"_blank"} href="https://instagram.com/indigo_mental_official?igshid=1stpm1b8qq4dx">
        <InstagramIcon />
      </a>
    </div>
  );
};
